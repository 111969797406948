<template>
  <!-- Sidebar -->
  <div
    class="border-t-2 fixed flex-col top-14 left-0 invisible md:visible hover:block md:w-64 bg-white h-full transition-all duration-300 z-40 sidebar"
  >
    <div class="flex items-center justify-center h-14 mt-4 profile border-b-2">
      <div class="pt-4 pb-4 px-6 mb-4">
        <div class="flex items-center">
          <div class="shrink-0">
            <img :src="profileImagePath" class="rounded-full w-10" alt="Avatar" />
          </div>
          <div class="grow ml-3 flex flex-col">
            <span class="text-xs p-0 m-0">{{
              getRoleName(userInfo.permissions?.role)
            }}</span>
            <p class="text-sm font-semibold p-0 m-0">
              {{ getUserName(userInfo.name) }}
            </p>
          </div>
        </div>
      </div>
    </div>
    <div
      class="overflow-y-auto overflow-x-hidden flex flex-col justify-between flex-grow"
    >
      <ul class="flex flex-col py-4 space-y-1">
        <li class="px-5">
          <div class="flex flex-row items-center h-8">
            <div class="text-sm font-light tracking-wide text-gray-600 uppercase">
              Principal
            </div>
          </div>
        </li>
        <NavMenu :menu="menu" v-for="(menu, index) in mainMenu" :key="index" />
        <li class="px-5">
          <div class="flex flex-row items-center mt-2 h-8">
            <div class="text-sm font-light tracking-wide text-gray-600 uppercase">
              Configuração
            </div>
          </div>
        </li>
        <NavMenu :menu="menu" v-for="(menu, index) in configMenu" :key="index" />
        <li class="px-5">
          <div class="flex flex-row items-center mt-2 h-8">
            <div class="text-sm font-light tracking-wide text-gray-600 uppercase">
              Ajuda
            </div>
          </div>
        </li>
        <NavMenu :menu="menu" v-for="(menu, index) in supportMenu" :key="index" />
        <!--  <li class="px-5 hidden md:block" v-if="hasClientUserAdminRole">
          <div class="flex flex-row items-center mt-2 h-8">
            <div class="text-sm font-light tracking-wide text-gray-600 uppercase">
              API
            </div>
          </div>
        </li>
        <NavMenu :menu="menu" v-for="(menu, index) in integrationMenu" :key="index" /> -->
      </ul>
      <p class="mb-14 px-5 py-3 hidden md:block text-center text-xs">
        <span
          class="block items-center rounded-md bg-indigo-50 px-2 py-1 text-xs font-medium text-indigo-700 ring-1 ring-inset ring-indigo-700/10"
          >VERSÃO BETA</span
        >
        Copyright @{{ new Date().getFullYear() }}
      </p>
    </div>
  </div>
  <!-- ./Sidebar -->
</template>
<script>
import NavMenu from "./Menu.vue";
import { RoleToString } from "../../_helpers/role";
import { Menu, MenuButton, MenuItems, MenuItem } from "@headlessui/vue";
import { hasPermission, permissions } from "../../_helpers/permission";
export default {
  name: "SideBar",
  components: {
    NavMenu,
    Menu,
    MenuButton,
    MenuItems,
    MenuItem,
  },
  props: {
    userInfo: {},
  },
  data() {
    return {
      profileImagePath: require("@/assets/user_default_grey.png"),
      mainMenu: [],
      configMenu: [],
      supportMenu: [],
      integrationMenu: [],
    };
  },
  async mounted() {
    this.configMenu = await this.fillConfigMenuArrayPermissions();
    this.supportMenu = await this.fillSupportMenuArrayPermissions();
    //this.integrationMenu = await this.fillIntegrationMenuArrayPermissions();
    this.mainMenu = await this.fillMainMenuArrayPermissions();
  },

  methods: {
    getUserName(name) {
      const splittedName = name.split(" ");
      if (splittedName.length == 1) {
        return name;
      }

      return `${splittedName[0]} ${splittedName[splittedName.length - 1]}`;
    },
    getRoleName(role) {
      return RoleToString(role);
    },
    async fillConfigMenuArrayPermissions() {
      return [
        {
          path: "/account",
          text: "Conta",
          icon: "UserIcon",
          hasPermission: true,
          id: "account",
        },
        {
          path: "/signature",
          text: "Assinatura",
          icon: "CreditCardIcon",
          hasPermission: hasPermission(permissions.PRIVILEGE_HANDLE_ACCOUNT_SIGNATURE),
          id: "signature",
        },
      ];
    },
    async fillSupportMenuArrayPermissions() {
      return [
        {
          path: "/support",
          text: "Suporte",
          icon: "SupportIcon",
          hasPermission: true,
          id: "support",
        },
      ];
    },
    async fillIntegrationMenuArrayPermissions() {
      return [
        {
          path: "/integration",
          text: "Integração",
          icon: "IntegrationIcon",
          hasPermission: true,
          id: "integration",
        },
      ];
    },
    async fillMainMenuArrayPermissions() {
      return [
        {
          path: "/dashboard",
          text: "Dashboard",
          icon: "GraphLineUpIcon",
          hasPermission: true,
          id: "dashboard",
        },
        {
          path: "/users",
          text: "Usuários",
          icon: "UsersIcon",
          hasPermission: hasPermission(permissions.PRIVILEGE_USER_CREATE),
          id: "user",
        },
        {
          path: "/foods",
          text: "Alimentos",
          icon: "ChickenIcon",
          hasPermission: true,
          id: "food",
        },
        {
          path: "/brands",
          text: "Marcas",
          icon: "BrandIcon",
          hasPermission: hasPermission(permissions.PRIVILEGE_BRAND_LIST),
          id: "food-brand",
        },
        {
          text: "Rotulagem",
          icon: "LabelIcon",
          hasPermission: hasPermission(permissions.PRIVILEGE_RECIPE_LIST),
          id: "label",
          subMenus: [
            {
              path: "/label/recipes",
              text: "Receitas",
              icon: "CookHatIcon",
              hasPermission: hasPermission(permissions.PRIVILEGE_RECIPE_LIST),
              id: "label-recipe",
            },
            {
              path: "/label/recipe-requests",
              text: "Requisições",
              icon: "RequestIcon",
              hasPermission: hasPermission(
                permissions.PRIVILEGE_REQUEST_RECIPE_LABEL_REQUEST
              ),
              id: "label-recipe-requests",
            },
          ],
        },
        {
          text: "Rastreamento",
          icon: "TrackingIcon",
          hasPermission: hasPermission(permissions.PRIVILEGE_GENERATE_TRACING_TAGS),
          id: "tracking-tag",
          subMenus: [
            {
              path: "/tracking/tags",
              text: "Etiquetas",
              icon: "TagIcon",
              hasPermission: hasPermission(permissions.PRIVILEGE_GENERATE_TRACING_TAGS),
              id: "tracking-tag",
            },
          ],
        },
      ];
    },
  },
};
</script>
