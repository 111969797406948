<template>
    <svg viewBox="0 0 48 48" xmlns="http://www.w3.org/2000/svg" fill="#000000">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <title>wifi-error</title>
            <g id="Layer_2" data-name="Layer 2">
                <g id="invisible_box" data-name="invisible box">
                    <rect width="48" height="48" fill="none"></rect>
                </g>
                <g id="Q3_icons" data-name="Q3 icons">
                    <g>
                        <path
                            d="M19.9,27.3l-.2-1.7a15.6,15.6,0,0,0-7.4,4.9,1.9,1.9,0,0,0-.4,1.2,2,2,0,0,0,.4,1.3,2,2,0,0,0,3.1,0,11.2,11.2,0,0,1,5.3-3.5A3.5,3.5,0,0,1,19.9,27.3Z">
                        </path>
                        <path
                            d="M28.3,25.6l-.2,1.7a3.5,3.5,0,0,1-.8,2.2A11.2,11.2,0,0,1,32.6,33a2,2,0,0,0,3.1,0,2.1,2.1,0,0,0,0-2.5A15.6,15.6,0,0,0,28.3,25.6Z">
                        </path>
                        <path
                            d="M19.1,17.5A23,23,0,0,0,7.5,23.9a2,2,0,0,0-.6,1.4,2.8,2.8,0,0,0,.4,1.2,1.9,1.9,0,0,0,3,.2,18.8,18.8,0,0,1,9.1-5.1Z">
                        </path>
                        <path
                            d="M28.9,17.5l-.3,4.1a18.8,18.8,0,0,1,9.1,5.1,1.9,1.9,0,0,0,3-.2,2,2,0,0,0-.2-2.6A23,23,0,0,0,28.9,17.5Z">
                        </path>
                        <path
                            d="M18.5,9.5A32,32,0,0,0,2.6,17.4a2.1,2.1,0,0,0-.2,2.7h0a2,2,0,0,0,3,.2,27.7,27.7,0,0,1,13.4-6.8Z">
                        </path>
                        <path
                            d="M45.4,17.4A32,32,0,0,0,29.5,9.5l-.3,4a27.7,27.7,0,0,1,13.4,6.8,2,2,0,0,0,3-.2h0A2.1,2.1,0,0,0,45.4,17.4Z">
                        </path>
                        <circle cx="24" cy="38" r="5"></circle>
                        <path
                            d="M23.9,29h.2a1.9,1.9,0,0,0,2-1.9L27.7,7a3.7,3.7,0,1,0-7.4,0l1.6,20.1A1.9,1.9,0,0,0,23.9,29Z">
                        </path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
</template>