import axios from 'axios'
import axiosRetry from 'axios-retry';
import { useAuth } from '../views/login/store'
import Notify from './../_helpers/toastNotification'

const http = axios.create({
  baseURL: process.env.VUE_APP_BACKEND_BASE_URL,
  headers: {
    'Accept': 'application/json',
    'Content-Type': 'application/json'
  }
})

axiosRetry(http, {
  retries: 1,
  retryDelay: () => 2000,
  retryCondition: (error) => retryConditionFun(error),
  onRetry: async (retryCount, error, requestConfig) => await onRetryFun(retryCount, error, requestConfig),
});

async function onRetryFun(retryCount, error, requestConfig) {
  if (isExceptionRoute(error.response.config.url)) {
    return
  }

  const authStore = useAuth();
  const { refreshToken, logout } = authStore
  console.log(`retry count: `, retryCount);
  if (retryCount <= 1) {
    await refreshToken().then(() => {
      requestConfig.headers["authorization"] = `Bearer ${authStore.user.data.accessToken}`
    }).catch(error => {
      logout();
      Notify.error("Sessão expirada");
    });
  }
}

async function retryConditionFun(error) {
  const errorStatus = error.response.status;
  if ((errorStatus == 401 && !isExceptionRoute(error.config.url)) || errorStatus == 500) {
    return true;
  } else {
    return false;
  }
}

function isExceptionRoute(url) {
  const routesException = [
    'auth/signin',
    'auth/signup',
    'auth/refresh-token',
  ]
  return routesException.some(string => string.includes(url));
}

// inject user token BEFORE request
http.interceptors.request.use(
  (config) => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      config.headers['authorization'] = `Bearer ${user?.accessToken}`
    }
    return config;
  },
  (error) => Promise.reject(error),
);

// inject user token AFTER request
http.interceptors.response.use(
  response => response,
  error => {
    const errorStatus = error.response.status;
    if (errorStatus == 500) {
      Notify.error("Ocorreu um erro interno!")
    }
    return Promise.reject(error);
  }
);

export default http;