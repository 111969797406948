<template>
  <SimpleCard
    v-for="(card, index) in cardDashboard"
    :key="index"
    :data="card"
  />
</template>
<script setup>
import { onMounted, ref } from "vue";
import SimpleCard from "../../../components/dashboard/SimpleCard.vue";
import dashboardHandler from "../dashboard-handler";

const cardDashboard = ref([]);

onMounted(() => {
  dashboardHandler
    .activedRecipes()
    .then((response) => cardDashboard.value.push(response));
  dashboardHandler
    .inactivedRecipes()
    .then((response) => cardDashboard.value.push(response));
});
</script>
