<template>
  <div
    class="min-h-screen bg-no-repeat bg-cover bg-center xl:bg-login-background-xl lg:bg-login-background-lg bg-login-background-md"
  >
    <div class="flex xl:justify-end justify-center">
      <div class="xl:bg-gray-100 min-h-screen xl:w-1/2 flex justify-center items-center">
        <div
          class="py-5 px-12 2xl:w-3/6 xl:w-4/6 bg-white shadow-lg rounded-lg my-20"
          id="form-fields"
        >
          <LoginForm @toggleForm="funToggleForm" v-if="toggleForm == 'login'" />
          <RegisterForm
            @toggleForm="funToggleForm"
            v-else-if="toggleForm == 'register'"
          />
          <RequestPasswordRecovery @toggleForm="funToggleForm" v-else />
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { onMounted, ref } from "vue";
import LoginForm from "./LoginForm.vue";
import RegisterForm from "./RegisterForm.vue";
import RequestPasswordRecovery from "./RequestPasswordRecovery.vue";
import { useApp } from "../../store";
const toggleForm = ref("login");
const appStore = useApp();

onMounted(() => {
  appStore.loading = false;
});

function funToggleForm(form) {
  toggleForm.value = form;
}
</script>
<style scoped></style>
