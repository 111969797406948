<template>
    <svg viewBox="0 0 64 64" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
        xml:space="preserve" xmlns:serif="http://www.serif.com/"
        style="fill-rule:evenodd;clip-rule:evenodd;stroke-linejoin:round;stroke-miterlimit:2;" fill="#000000">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
        <rect id="Icons" x="-1024" y="-64" width="1280" height="800" style="fill:none;"></rect>
        <g id="Icons1" serif:id="Icons">
            <g id="Strike"> </g>
            <g id="H1"> </g>
            <g id="H2"> </g>
            <g id="H3"> </g>
            <g id="list-ul"> </g>
            <g id="hamburger-1"> </g>
            <g id="hamburger-2"> </g>
            <g id="list-ol"> </g>
            <g id="list-task"> </g>
            <g id="trash"> </g>
            <g id="vertical-menu"> </g>
            <g id="horizontal-menu"> </g>
            <g id="sidebar-2"> </g>
            <g id="Pen"> </g>
            <g id="Pen1" serif:id="Pen"> </g>
            <g id="clock"> </g>
            <g id="external-link"> </g>
            <g id="hr"> </g>
            <g id="info"> </g>
            <g id="warning"> </g>
            <g id="plus-circle"> </g>
            <g id="minus-circle"> </g>
            <g id="vue"> </g>
            <g id="cog"> </g>
            <g id="logo"> </g>
            <g id="radio-check"> </g>
            <g id="eye-slash"> </g>
            <g id="eye"> </g>
            <g id="toggle-off"> </g>
            <g id="shredder"> </g>
            <g id="spinner--loading--dots-" serif:id="spinner [loading, dots]">
                <path
                    d="M46.03,32c0,-2.751 2.233,-4.985 4.985,-4.985c2.751,0 4.985,2.234 4.985,4.985c0,2.751 -2.234,4.985 -4.985,4.985c-2.752,0 -4.985,-2.234 -4.985,-4.985Z"
                    style="fill:#d9d9d9;"></path>
                <path
                    d="M41.92,41.92c1.946,-1.945 5.105,-1.945 7.051,0c1.945,1.946 1.945,5.105 0,7.051c-1.946,1.945 -5.105,1.945 -7.051,0c-1.945,-1.946 -1.945,-5.105 0,-7.051Z"
                    style="fill:#b3b3b3;"></path>
                <circle cx="32" cy="51.015" r="4.985" style="fill:#8c8c8c;"></circle>
                <path
                    d="M22.08,41.92c1.945,1.946 1.945,5.105 0,7.051c-1.946,1.945 -5.105,1.945 -7.051,0c-1.945,-1.946 -1.945,-5.105 0,-7.051c1.946,-1.945 5.105,-1.945 7.051,0Z"
                    style="fill:#666;"></path>
                <path
                    d="M17.97,32c0,2.751 -2.233,4.985 -4.985,4.985c-2.751,0 -4.985,-2.234 -4.985,-4.985c0,-2.751 2.234,-4.985 4.985,-4.985c2.752,0 4.985,2.234 4.985,4.985Z"
                    style="fill:#404040;"></path>
                <path
                    d="M22.08,22.08c-1.946,1.945 -5.105,1.945 -7.051,0c-1.945,-1.946 -1.945,-5.105 0,-7.051c1.946,-1.945 5.105,-1.945 7.051,0c1.945,1.946 1.945,5.105 0,7.051Z"
                    style="fill:#404040;"></path>
                <circle cx="32" cy="12.985" r="4.985"></circle>
            </g>
            <g id="react"> </g>
            <g id="check-selected"> </g>
            <g id="turn-off"> </g>
            <g id="code-block"> </g>
            <g id="user"> </g>
            <g id="coffee-bean"> </g>
            <g id="coffee-beans">
                <g id="coffee-bean1" serif:id="coffee-bean"> </g>
            </g>
            <g id="coffee-bean-filled"> </g>
            <g id="coffee-beans-filled">
                <g id="coffee-bean2" serif:id="coffee-bean"> </g>
            </g>
            <g id="clipboard"> </g>
            <g id="clipboard-paste"> </g>
            <g id="clipboard-copy"> </g>
            <g id="Layer1"> </g>
        </g>
    </g>
</svg></template>