import { methods as msgNotify } from "vue3-vt-notifications/src/notifications.js";

const duration = 4000

class Notify {
  success(text, sec = duration) {
    msgNotify.notify(
      {
        title: "Sucesso",
        text: text,
        type: "success",
        group: "toastNotification",
      },
      sec
    )
  }
  error(text, sec = duration) {
    msgNotify.notify(
      {
        title: "Erro",
        text: text,
        type: "error",
        group: "toastNotification",
      },
      sec
    );
  }
}

export default new Notify();