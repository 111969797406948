<template>
  <div id="app" class="bg-gray-100 font-sans">
    <NoInternetMessage />
    <Loading v-if="appStore.loading" />
    <Alert />
    <div v-if="authStore.user.status.loggedIn && hasShortcutPageFlag">
      <!--  shortcut page -->
      <div class="min-h-screen bg-gray-100">
        <router-view />
      </div>
    </div>
    <div
      v-else-if="authStore.user.status.loggedIn"
      class="min-h-screen flex flex-col flex-auto flex-shrink-0 antialiased text-black"
    >
      <!-- logged user page -->
      <Header />
      <Sidebar :user-info="authStore.user.data" />
      <div class="h-full mt-14 md:ml-64 bg-gray-100 main-content">
        <router-view />
        <Footer />
      </div>
    </div>
    <div v-else>
      <!-- login page -->
      <router-view />
    </div>
  </div>
</template>
<script setup>
import Alert from "./components/Alert.vue";
import Loading from "./components/Loading.vue";
import Footer from "./components/nav/Footer.vue";
import Header from "./components/nav/Header.vue";
import Sidebar from "./components/nav/Sidebar.vue";
import { useAuth } from "./views/login/store";
import { useApp } from "./store";
import { onBeforeMount, ref } from "vue";
import { isShortcutPage } from "./_helpers/navigation/shortcutScreen";
import NoInternetMessage from "./components/NoInternetMessage.vue";

const appStore = useApp();
const authStore = useAuth();
const hasShortcutPageFlag = ref(false);

onBeforeMount(() => (hasShortcutPageFlag.value = isShortcutPage()));
</script>
