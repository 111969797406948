export const Role = {
    ClientUserAdmin: 'ROLE_CLIENT_USER_ADMIN',
    ClientUserOperator: 'ROLE_CLIENT_USER_OPERATOR',
    ClientNutritionistUser: 'ROLE_CLIENT_NUTRITIONIST_USER',
}

export const RoleToString = (role) => {
    if (role === Role.ClientNutritionistUser) {
        return "Nutricionista"
    } else if (role === Role.ClientUserAdmin) {
        return "Administrador"
    } else {
        return "Operador"
    }
}
