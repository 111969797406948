import dashboardService from "./services/dashboard.service";

class DashboardHandler {
  inactivedUsers() {
    return dashboardService.getInactivedUsers().then((response) => {
      return {
        ...response.data.response,
        color: "red",
      };
    })
  }

  activedUsers() {
    return dashboardService.getActivedUsers().then((response) => {
      return {
        ...response.data.response,
        color: "green",
      };
    })
  }

  inactivedRecipes() {
    return dashboardService.getInactivedRecipes().then((response) => {
      return {
        ...response.data.response,
        color: "red",
      };
    })
  }

  activedRecipes() {
    return dashboardService.getActivedRecipes().then((response) => {
      return {
        ...response.data.response,
        color: "green",
      };
    })
  }
}

export default new DashboardHandler();