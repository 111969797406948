import { createApp } from 'vue'
import App from './App.vue'

import router from './router'

import './styles/app.css';

import Notifications from 'vue3-vt-notifications';

import { vMaska } from "maska"

import { createPinia } from "pinia";
import './registerServiceWorker'

const pinia = createPinia();

const app = createApp(App)

if (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'stage') {
  app.config.devtools = false;
  app.config.debug = false
  app.config.silent = true
  app.config.productionTip = false;
}

/* app.directive('number-format', (el, binding, vnode) => {
    console.log(el)
    el.value = el.value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
})
 */
app
  .use(router)
  .directive("maska", vMaska)
  .use(pinia)
  .use(Notifications)
  .mount('#app')