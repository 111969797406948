import { permissions } from '../_helpers/permission';
import { Role } from '../_helpers/role'
import DashboardComponent from '../views/dashboard/Index.vue'
import { useAuth } from '../views/login/store'

const getUserRole = async () => {
    const authStore = useAuth();
    return await authStore.getUserRole;
}

//users
const IndexUserComponent = () => import('../views/user/Index.vue')
const RegisterUserComponent = () => import('../views/user/Register.vue')
const UpdateUserComponent = () => import('../views/user/Update.vue')

// ###### label ######
//food
const IndexFoodComponent = () => import('../views/food/Index.vue')
const RegisterFoodComponent = () => import('../views/food/Register.vue')
const UpdateFoodComponent = () => import('../views/food/Update.vue')

//food brand
const IndexFoodBrandComponent = () => import('../views/brand/Index.vue')
const RegisterFoodBrandComponent = () => import('../views/brand/Register.vue')
const UpdateFoodBrandComponent = () => import('../views/brand/Update.vue')

//Recipe
//const RegisterLabelRecipeComponent = RegisterLabelRecipeComponentPath[await getUserRole()]
//const UpdateLabelRecipeComponent = UpdateLabelRecipeComponentPath[await getUserRole()]
const IndexLabelRecipeComponent = () => import('../views/label/recipe/Index.vue')

//support
const IndexSupportComponent = () => import('../views/support/Index.vue')

//tracking tag
const IndexTrackingTagComponent = () => import('../views/tracking/tag/Index.vue')
const GenerateTrackingTagComponent = () => import('../views/tracking/tag/Generate.vue')

//integration
const IndexIntegrationComponent = () => import('../views/integration/Index.vue')

//assignature
const IndexsignatureComponent = () => import('../views/signature/Index.vue')

//recipe request
const RegisterLabelRecipeRequestComponent = () => import('../views/label/recipe-request/Register.vue')
const UpdateLabelRecipeRequestComponent = () => import('../views/label/recipe-request/Update.vue')
const IndexLabelRecipeRequestComponent = () => import('../views/label/recipe-request/Index.vue')

const IndexAccountComponent = () => import('../views/account/Index.vue')

export default [
    //DASHBOARD
    {
        path: '/dashboard',
        component: DashboardComponent,
        meta: { id: "dashboard", name: 'Dashboard' }
    },
    {//USERS
        path: '/users',
        component: IndexUserComponent,
        meta: { permission: permissions.PRIVILEGE_USER_LIST, id: "user", name: 'Usuários' }
    },
    {
        path: '/user/new',
        component: RegisterUserComponent,
        meta: { permission: permissions.PRIVILEGE_USER_CREATE, id: "user", name: 'Criar Usuário' }
    },
    {
        path: '/user/:id/edit',
        component: UpdateUserComponent,
        meta: { permission: permissions.PRIVILEGE_USER_UPDATE, id: "user", name: 'Editar Usuário' }
    },
    { //LABEL FOOD
        path: '/foods',
        component: IndexFoodComponent,
        meta: { permission: permissions.PRIVILEGE_FOOD_LIST, id: "food", name: 'Alimentos' }
    },
    {
        path: '/food/new',
        component: RegisterFoodComponent,
        meta: { permission: permissions.PRIVILEGE_FOOD_CREATE, id: "food", name: 'Criar Alimento' }
    },
    {
        path: '/food/:id/edit',
        component: UpdateFoodComponent,
        meta: { permission: permissions.PRIVILEGE_FOOD_UPDATE, id: "food", name: 'Editar Alimento' }
    },
    { //LABEL FOOD BRANDS
        path: '/brands',
        component: IndexFoodBrandComponent,
        meta: { permission: permissions.PRIVILEGE_BRAND_LIST, id: "food-brand", name: 'Marcas' }
    },
    {
        path: '/brand/new',
        component: RegisterFoodBrandComponent,
        meta: { permission: permissions.PRIVILEGE_BRAND_CREATE, id: "food-brand", name: 'Criar Marca' }
    },
    {
        path: '/brand/:id/edit',
        component: UpdateFoodBrandComponent,
        meta: { permission: permissions.PRIVILEGE_BRAND_UPDATE, id: "food-brand", name: ' Editar Marca' }
    },
    { //LABEL RECIPE
        path: '/label/recipes',
        component: IndexLabelRecipeComponent,
        meta: { permission: permissions.PRIVILEGE_RECIPE_LIST, id: "recipe", name: 'Receitas' }
    },
    {
        path: '/label/recipe/new',
        component: async () => {
            if (await getUserRole() == Role.ClientNutritionistUser) {
                return import('../views/label/recipe/nutritionist/Register.vue')
            } else {
                return import('../views/label/recipe/user/Register.vue')
            }
        },
        meta: { permission: permissions.PRIVILEGE_RECIPE_CREATE, id: "label-recipe", name: 'Rotulagem - Criar Receita' }
    },
    {
        path: '/label/recipe/:id/edit',
        component: async () => {
            if (await getUserRole() == Role.ClientNutritionistUser) {
                return import('../views/label/recipe/nutritionist/Update.vue')
            } else {
                return import('../views/label/recipe/user/Update.vue')
            }
        },
        meta: { permission: permissions.PRIVILEGE_RECIPE_UPDATE, id: "label-recipe", name: 'Rotulagem - Editar Receita' }
    },
    { //LABEL RECIPE REQUESTS
        path: '/label/recipe-requests',
        component: IndexLabelRecipeRequestComponent,
        meta: { permission: permissions.PRIVILEGE_RECIPE_LIST, id: "label-recipe-requests", name: 'Rotulagem - Req. Receitas' }
    },
    {
        path: '/label/recipe-requests/new',
        component: RegisterLabelRecipeRequestComponent,
        meta: { permission: permissions.PRIVILEGE_RECIPE_CREATE, id: "label-recipe-requests", name: 'Rotulagem - Criar Req. Receita' }
    },
    {
        path: '/label/recipe-requests/:id/edit',
        component: UpdateLabelRecipeRequestComponent,
        meta: { permission: permissions.PRIVILEGE_RECIPE_UPDATE, id: "label-recipe-requests", name: 'Rotulagem - Editar Req.  Receita' }
    },
    { //TRACKING TAG
        path: '/tracking/tags',
        component: IndexTrackingTagComponent,
        meta: { permission: permissions.PRIVILEGE_GENERATE_TRACING_TAGS, id: "tracking-tag", name: 'Rastreamento - Etiquetas' }
    },
    {
        path: '/tracking/tag/new',
        component: GenerateTrackingTagComponent,
        meta: { permission: permissions.PRIVILEGE_GENERATE_TRACING_TAGS, id: "tracking-tag", name: 'Rastreamento - Gerar Etiquetas' }
    },
    {//PROFILE
        path: '/account',
        component: IndexAccountComponent,
        meta: { id: "account", name: 'Conta' }
    },
    {//SIGNATURE
        path: '/signature',
        component: IndexsignatureComponent,
        meta: { permission: permissions.PRIVILEGE_HANDLE_ACCOUNT_SIGNATURE, id: "signature", name: 'Assinatura' }
    },
    {//SUPORT
        path: '/support',
        component: IndexSupportComponent,
        meta: { id: "support", name: 'Suporte' }
    },
    {//INTEGRATION
        path: '/integration',
        component: IndexIntegrationComponent,
        meta: { id: "integration", name: 'Integração' }
    }
]