<template>
  <div>
    <div>
      <h1 class="text-2xl font-bold">Recuperação de senha</h1>
    </div>
    <div class="mt-5" id="form-fields">
      <div
        class="bg-green-100 sm:rounded p-6 mb-2"
        v-if="requestPasswordRecoverySent"
      >
        <p class="tracking-tight font-bold">
          E-mail de recuperação de senha enviada!
        </p>
      </div>
      <div v-else>
        <label class="block text-md mb-2" for="email">Email</label>
        <input
          class="px-4 w-full border-2 py-2 rounded-md text-md outline-none"
          type="email"
          name="email"
          v-model="passwordRecovery.email"
          placeholder="Email"
        />
        <span name="email" class="text-red-600 text-sm validation-span" />
        <div>
          <button
            @click="requestPasswordRecovery()"
            class="mt-4 w-full uppercase inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
          >
            <SpinnerIcon
              v-show="loading"
              class="animate-spin h-5 w-5 mr-2"
              color="#f3f4f6"
            />
            Recuperar
          </button>
        </div>
      </div>
    </div>

    <p class="mt-2">
      Já tem uma conta?
      <a
        class="cursor-pointer text-sm text-blue-600"
        @click="toggleForm('login')"
        >Logar-se</a
      >
    </p>
  </div>
</template>
<script setup>
import { ref } from "vue";
import * as yup from "../../validator";
import AuthService from "./services/auth.service";
import SpinnerIcon from "../../components/icons/SpinnerIcon.vue";
import { setFormFieldsError } from "../../_helpers/formFieldsHandler";

const emit = defineEmits(["toggleForm"]);

const schema = yup.object().shape({
  email: yup.string().email().required().max(50).label("Email").min(5),
});

const passwordRecovery = ref({});
const loading = ref(false);
const requestPasswordRecoverySent = ref(false);

function toggleForm(form) {
  emit("toggleForm", form);
}

async function requestPasswordRecovery() {
  loading.value = true;

  let errors = [];
  await schema
    .validate(passwordRecovery.value, { abortEarly: false })
    .catch((err) => {
      err.inner.forEach((e) => {
        errors.push({
          name: e.path,
          msg: e.message,
        });
      });
    });

  if (errors.length > 0) {
    loading.value = false;
    setFormFieldsError(errors);
    return;
  }

  await AuthService.requestPasswordRecovery(passwordRecovery.value.email).then(
    (response) => {
      this.loading = false;
      requestPasswordRecoverySent.value = true;
    },
    (error) => {
      loading.value = false;
      requestPasswordRecoverySent.value = true;
    }
  );
}
</script>
