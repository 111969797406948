<template>
  <TransitionRoot as="template" :show="open" class="z-50">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto" @close="open = false">
      <div
        class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
      >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay
            class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
          />
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span
          class="hidden sm:inline-block sm:align-middle sm:h-screen"
          aria-hidden="true"
          >&#8203;</span
        >
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          enter-to="opacity-100 translate-y-0 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 translate-y-0 sm:scale-100"
          leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
        >
          <div
            :class="`inline-block align-middle bg-white rounded-lg text-left shadow-xl transform transition-all sm:my-8`"
          >
            <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4 w-screen sm:w-full">
              <div class="flex w-full">
                <div
                  v-if="showIcon"
                  class="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-green-100 sm:mx-0 sm:h-15 sm:w-15"
                >
                  <component class="h-10 w-10 mb-1" v-bind:is="dialogData.icon" />
                </div>
                <div class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                  <DialogTitle as="h3" class="text-2xl leading-6 text-gray-900 font-bold">
                    {{ dialogData.title }}
                  </DialogTitle>
                  <div class="mt-2">
                    <p class="text-gray-700">
                      {{ dialogData.description }}
                    </p>
                    <slot name="dialog-body" v-bind:dialogData="dialogData"></slot>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <slot name="dialog-buttons" v-bind:dialogData="dialogData"></slot>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot,
} from "@headlessui/vue";

import QuestionCicleIcon from "./icons/QuestionCicleIcon.vue";
import NoInternetIcon from "./icons/NoInternetIcon.vue";
import PrinterIcon from "./icons/PrinterIcon.vue";

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    QuestionCicleIcon,
    NoInternetIcon,
    PrinterIcon,
  },
  props: {
    showIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      open: false,
      dialogData: {},
    };
  },
  methods: {
    openDialog(dialogData) {
      this.dialogData = dialogData;
      this.open = true;
    },
    closeDialog() {
      this.open = false;
    },
  },
};
</script>
