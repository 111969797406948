<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <circle cx="12" cy="12" r="3" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            </circle>
            <circle cx="12" cy="4" r="2" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            </circle>
            <circle cx="20" cy="12" r="2" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            </circle>
            <circle cx="4" cy="12" r="2" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            </circle>
            <circle cx="12" cy="20" r="2" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            </circle>
            <path d="M12 6V9" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M15 12H18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M12 15V18" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
            <path d="M9 12H6" stroke="#000000" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"></path>
        </g>
    </svg>
</template>