//used to open a page from parent and not show navigation components
export function isShortcutPage() {
    const queryParams = getQueryParams(window.location.search);

    if (queryParams?.isShortcutPage && queryParams.isShortcutPage == "true") {
        return true;
    }

    return false;
}

export function openWindow(url) {
    const baseURL = window.location.origin;
    open(baseURL + url, '_blank', 950, 570);
}

// open child window on screen center
function open(url, name, width, height) {
    const left = (window.innerWidth - width) / 2;
    const top = (window.innerHeight - height) / 2;
    const config = `width=${width},height=${height},left=${left},top=${top},toolbar=0,status=0`;

    window.open(url, name, config);
}

function getQueryParams(queryString) {
    const searchParams = new URLSearchParams(queryString);
    const params = {};

    for (const [key, value] of searchParams) {
        params[key] = value;
    }

    return params;
}
