<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path
                d="M17 15.25C16.9015 15.2504 16.8038 15.2312 16.7128 15.1934C16.6218 15.1557 16.5392 15.1001 16.47 15.03L12 10.56L7.53 15C7.38904 15.0914 7.22148 15.1328 7.05418 15.1176C6.88687 15.1024 6.72951 15.0315 6.60731 14.9162C6.48512 14.8009 6.40516 14.648 6.38026 14.4818C6.35536 14.3157 6.38696 14.146 6.46999 14L11.47 8.99998C11.6106 8.85953 11.8012 8.78064 12 8.78064C12.1987 8.78064 12.3894 8.85953 12.53 8.99998L17.53 14C17.6704 14.1406 17.7493 14.3312 17.7493 14.53C17.7493 14.7287 17.6704 14.9194 17.53 15.06C17.3853 15.1907 17.1948 15.259 17 15.25Z"
                fill="#000000"></path>
        </g>
    </svg>
</template>
<script>
import icon from "./icon.js";
export default Object.assign({}, icon);
</script>