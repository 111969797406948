<template>
  <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
    <g id="SVGRepo_iconCarrier">
      <path
        d="M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
        stroke="#000000"
        stroke-width="1.5"
      ></path>
      <path
        d="M15 12C15 13.6569 13.6569 15 12 15C10.3431 15 9 13.6569 9 12C9 10.3431 10.3431 9 12 9C13.6569 9 15 10.3431 15 12Z"
        stroke="#000000"
        stroke-width="1.5"
      ></path>
      <path
        d="M2 12L4 12"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
      ></path>
      <path
        d="M20 12L22 12"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
      ></path>
      <path d="M12 4V2" stroke="#000000" stroke-width="1.5" stroke-linecap="round"></path>
      <path
        d="M12 22V20"
        stroke="#000000"
        stroke-width="1.5"
        stroke-linecap="round"
      ></path>
    </g>
  </svg>
</template>
