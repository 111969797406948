import http from '../../../http';

const PATH = 'auth/';

class AuthService {
    async login(user) {
        return await http
            .post(PATH + 'signin', user)
            .then(response => {
                const responseBody = response.data.response
                if (responseBody.accessToken) {
                    localStorage.setItem('user', JSON.stringify(responseBody));
                }

                return responseBody;
            });
    }

    async register(user) {
        return await http
            .post(PATH + 'signup', user)
            .then(response => response);
    }

    async refreshToken(refreshToken) {
        return await http
            .post(PATH + 'refresh-token', {
                refreshToken: refreshToken
            })
            .then(response => {
                const responseBody = response.data.response
                if (responseBody.accessToken) {
                    localStorage.setItem('user', JSON.stringify(responseBody));
                }

                return responseBody;
            });
    }

    async updatePassword(updatePasswordRequest) {
        return await http
            .put(PATH + 'update-password',
                updatePasswordRequest
            );
    }

    async confirmEmail(token) {
        return await http
            .get(PATH + 'confirm-email/' + token)
            .then(response => response);
    }

    async requestPasswordRecovery(email) {
        return await http
            .get(PATH + 'request-password-recovery/' + email)
            .then(response => response);
    }

    async logout() {
        await localStorage.removeItem('user');
    }
}

export default new AuthService();