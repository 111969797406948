export function setFormFieldsError(fieldsErrors = [], elementWithSpans) {
    console.error(fieldsErrors)
    const domElements = getDomElements(elementWithSpans);
    if (!domElements) {
        return;
    }

    const spanFields = domElements.querySelectorAll('span');

    fieldsErrors.forEach(fieldError => {
        spanFields.forEach(field => {
            if (field.getAttribute('class').includes('validation-span')) {
                if (field.getAttribute('name') == fieldError.name) {
                    field.innerText = fieldError.msg;
                    return;
                } else if (fieldsErrors.map(fieldError => fieldError.name).includes(field.getAttribute('name')))
                    return
                else
                    field.innerText = ""

            }
        })

    })
}

export function cleanFormField(fieldName, elementWithSpans) {
    const domElements = getDomElements(elementWithSpans);
    
    if (!domElements) {
        return;
    }

    const spanFields = domElements.querySelectorAll('span');

    spanFields.forEach(field => {
        if (field.getAttribute('name') == fieldName) {
            field.innerText = "";
            return;
        }
    })
}

function getDomElements(elementWithSpans) {
    if (elementWithSpans) {
        return document.getElementById(elementWithSpans);
    }
    return document.getElementById("form-fields");

}
