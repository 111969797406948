<template>
    <div
      class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50"
    >
      <div
        class="loading-spinner opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0"
      ></div>
    </div>
</template>
<script>
export default {
  name: "Loading",
  data() {
    return {};
  },
};
</script>
<style scoped>
.loading-spinner {
  width: 80px;
  top: 40%;
  height: 80px;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #16a34a;
  animation: s5 1s infinite linear;
}

.loading-spinner::before,
.loading-spinner::after {
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: s5 2s infinite;
}

.loading-spinner::after {
  margin: 8px;
  animation-duration: 3s;
}

@keyframes s5 {
  100% {
    transform: rotate(1turn);
  }
}
</style>
