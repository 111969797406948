import { useAuth } from "../views/login/store";

export const permissions = {
    /*### ACCOUNT ###*/
    PRIVILEGE_ACCOUNT_ATTACH_LOGO: "PRIVILEGE_ACCOUNT_ATTACH_LOGO",
    PRIVILEGE_HANDLE_COMPANY_INFO: "PRIVILEGE_HANDLE_COMPANY_INFO",
    PRIVILEGE_HANDLE_ACCOUNT_SIGNATURE: "PRIVILEGE_HANDLE_ACCOUNT_SIGNATURE",
    
    /*### RECIPE ###*/
    PRIVILEGE_RECIPE_ADD_RECIPE_INTO: "PRIVILEGE_RECIPE_ADD_RECIPE_INTO", //advanced functionality
    PRIVILEGE_RECIPE_INSERT_PICTURES: "PRIVILEGE_RECIPE_INSERT_PICTURES",
    PRIVILEGE_RECIPE_CREATE: "PRIVILEGE_RECIPE_CREATE",
    PRIVILEGE_RECIPE_UPDATE: "PRIVILEGE_RECIPE_UPDATE",
    PRIVILEGE_RECIPE_LIST: "PRIVILEGE_RECIPE_LIST",
    PRIVILEGE_RECIPE_DEACTIVATE: "PRIVILEGE_RECIPE_DEACTIVATE",

    /*### BRAND ###*/
    PRIVILEGE_BRAND_CREATE: "PRIVILEGE_BRAND_CREATE",
    PRIVILEGE_BRAND_DEACTIVATE: "PRIVILEGE_BRAND_DEACTIVATE",
    PRIVILEGE_BRAND_UPDATE: "PRIVILEGE_BRAND_UPDATE",
    PRIVILEGE_BRAND_LIST: "PRIVILEGE_BRAND_LIST",

    /*### REQUEST RECIPE LABEL ###*/
    PRIVILEGE_REQUEST_RECIPE_LABEL_REQUEST: "PRIVILEGE_REQUEST_RECIPE_LABEL_REQUEST",

    /*### EMAIL ###*/
    PRIVILEGE_PRIORITY_SUPPORT_EMAIL: "PRIVILEGE_PRIORITY_SUPPORT_EMAIL",

    /*### INTEGRATION ###*/
    PRIVILEGE_SYSTEM_INTEGRATION: "PRIVILEGE_SYSTEM_INTEGRATION",

    PRIVILEGE_GENERATE_TRACING_TAGS: "PRIVILEGE_GENERATE_TRACING_TAGS",

    /*### USER ###*/
    PRIVILEGE_USER_CREATE: 'PRIVILEGE_USER_CREATE',
    PRIVILEGE_USER_DEACTIVATE: 'PRIVILEGE_USER_DEACTIVATE',
    PRIVILEGE_USER_UPDATE: 'PRIVILEGE_USER_UPDATE',
    PRIVILEGE_USER_LIST: 'PRIVILEGE_USER_LIST',

    /*### FOOD ###*/
    PRIVILEGE_FOOD_CREATE: 'PRIVILEGE_FOOD_CREATE',
    PRIVILEGE_FOOD_DEACTIVATE: 'PRIVILEGE_FOOD_DEACTIVATE',
    PRIVILEGE_FOOD_UPDATE: 'PRIVILEGE_FOOD_UPDATE',
    PRIVILEGE_FOOD_LIST: 'PRIVILEGE_FOOD_LIST',
}

export function hasPermission(permission) {
    const authStore = useAuth();
    const currentUserPermissions = authStore.getUserPermissions;
    if (!currentUserPermissions) return;
    return currentUserPermissions.includes(permission)
}