<template>
  <div>
    <h1 class="text-2xl font-bold">Registro</h1>
  </div>
  <div v-if="!registeredSuccessfully">
    <div class="my-3">
      <label class="block text-md" for="name">Nome <a class="text-red-600">*</a></label>
      <input
        class="px-4 w-full border-2 py-2 rounded-md text-md outline-none"
        type="text"
        name="name"
        placeholder="Nome"
        v-model="registerData.name"
      />
      <span name="name" class="text-red-600 text-sm validation-span" />
    </div>
    <div class="my-3">
      <label class="block text-md" for="companyName"
        >Empresa <a class="text-red-600">*</a></label
      >
      <input
        class="px-4 w-full border-2 py-2 rounded-md text-md outline-none"
        type="text"
        name="companyName"
        placeholder="Empresa"
        v-model="registerData.companyName"
      />
      <span name="companyName" class="text-red-600 text-sm validation-span" />
    </div>
    <div class="my-3">
      <label class="block text-md" for="cnpj">CNPJ <a class="text-red-600">*</a></label>
      <input
        class="px-4 w-full border-2 py-2 rounded-md text-md outline-none"
        type="text"
        name="cnpj"
        v-maska
        data-maska="##.###.###/####-##"
        placeholder="CNPJ"
        v-model="registerData.cnpj"
      />
      <span name="cnpj" class="text-red-600 text-sm validation-span" />
    </div>
    <div class="my-3">
      <label class="block text-md" for="name"
        >Você é nutricionista?: <a class="text-red-600">*</a></label
      >
      <div class="w-full flex">
        <div class="radio mr-2">
          <input
            name="answer"
            type="radio"
            id="B1"
            hidden="hidden"
            value="ROLE_CLIENT_NUTRITIONIST_USER"
            v-model="registerData.role"
          />
          <label
            for="B1"
            class="px-2 py-1 rounded-md flex justify-center items-center font-bold cursor-pointer"
          >
            Sim
          </label>
        </div>
        <div class="radio">
          <input
            name="answer"
            type="radio"
            id="C1"
            hidden="hidden"
            value="ROLE_CLIENT_USER_ADMIN"
            v-model="registerData.role"
          />
          <label
            for="C1"
            class="px-2 py-1 rounded-md flex justify-center items-center font-bold cursor-pointer"
          >
            Não
          </label>
        </div>
      </div>
      <span name="role" class="text-red-600 text-sm validation-span" />
    </div>
    <div class="my-3" v-if="registerData.role == Role.ClientNutritionistUser">
      <label class="block text-md" for="crn">CRN <a class="text-red-600">*</a></label>
      <input
        class="px-4 w-full border-2 py-2 rounded-md text-md outline-none"
        type="text"
        name="crn"
        v-maska
        data-maska="######"
        placeholder="CRN"
        v-model="registerData.crn"
      />
      <span name="crn" class="text-red-600 text-sm validation-span" />
    </div>
    <div class="my-3">
      <label class="block text-md" for="phone"
        >Celular (WhatsApp) <a class="text-red-600 text-sm validation-span">*</a></label
      >
      <input
        class="px-4 w-full border-2 py-2 rounded-md text-md outline-none"
        type="text"
        name="phone"
        v-maska
        data-maska="(##) #####-####"
        placeholder="Celular"
        v-model="registerData.phone"
      />
      <span name="phone" class="text-red-600 text-sm validation-span" />
    </div>
    <div class="my-3">
      <label class="block text-md" for="email"
        >Email <a class="text-red-600 text-sm validation-span">*</a></label
      >
      <input
        class="px-4 w-full border-2 py-2 rounded-md text-md outline-none"
        type="email"
        name="email"
        placeholder="Email"
        v-model="registerData.email"
      />
      <span name="email" class="text-red-600 text-sm validation-span" />
    </div>
    <div class="my-3">
      <label class="block text-md" for="password"
        >Senha <a class="text-red-600">*</a></label
      >
      <input
        class="px-4 w-full border-2 py-2 rounded-md text-md outline-none"
        type="password"
        name="password"
        placeholder="Senha"
        v-model="registerData.password"
      />
      <span name="password" class="text-red-600 text-sm validation-span" />
    </div>
    <div class="my-3">
      <label class="block text-md" for="passwordConfirmation"
        >Confirme senha <a class="text-red-600">*</a></label
      >
      <input
        class="px-4 w-full border-2 py-2 rounded-md text-md outline-none"
        type="password"
        name="passwordConfirmation"
        placeholder="Confirme senha"
        v-model="registerData.passwordConfirmation"
      />
      <span name="passwordConfirmation" class="text-red-600 text-sm validation-span" />
    </div>

    <div>
      <button
        :disabled="loading"
        @click="createAccount()"
        class="w-full uppercase inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        <SpinnerIcon v-show="loading" class="animate-spin h-5 w-5 mr-2" color="#f3f4f6" />
        Registrar
      </button>
    </div>
  </div>
  <div class="p-4" v-else>
    <a>
      Cadastro realizado com sucesso!! 🥳 🥳
      <br />
      <br />
      Uma confirmação de cadastro foi enviado para o seu e-mail!
      <br />
      <br />
      <b>Caso o e-mail não esteja na caixa de entrada, verificar no spam</b> 🗑️
    </a>
    <button
      @click="toggleForm('login')"
      class="w-full bg-green-600 hover:bg-green-500 text-white py-2 rounded-md transition duration-100 mt-8"
    >
      OK
    </button>
  </div>

  <p class="mt-2">
    Já tem uma conta?
    <a class="cursor-pointer text-sm text-blue-600" @click="toggleForm('login')">Login</a>
  </p>
</template>
<script setup>
import { ref } from "vue";
import * as yup from "../../validator";
import AuthService from "./services/auth.service";
import SpinnerIcon from "../../components/icons/SpinnerIcon.vue";
import { setFormFieldsError } from "../../_helpers/formFieldsHandler";
import Notify from "./../../_helpers/toastNotification";
import { Role } from "../../_helpers/role";

const emit = defineEmits(["toggleForm"]);
const registerData = ref({});
const loading = ref(false);
const registeredSuccessfully = ref(false);

const schema = yup.object().shape({
  name: yup.string().required().label("Nome").min(4).max(100),
  companyName: yup.string().required().label("Empresa").max(100).nullable(),
  cnpj: yup.string().required().label("Empresa").min(18).max(18).nullable(),
  phone: yup.string().required().label("Celular").max(15).nullable(),
  email: yup.string().required().email().max(50).label("Email"),
  role: yup.string().required().label(""),
  crn: yup
    .string()
    .label("CRN")
    .max(6)
    .min(6)
    .when("role", {
      is: Role.ClientNutritionistUser,
      then: (schema) => schema.required(),
    }),
  password: yup
    .string()
    .required()
    .max(50)
    .label("Senha")
    .min(5)
    .test(
      "correct-format",
      "Não é permitido caracteres especiais",
      (value) => !/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(value)
    ),
  passwordConfirmation: yup
    .string()
    .oneOf([yup.ref("password"), null], "Senhas não coincidem")
    .max(50),
});

async function createAccount() {
  loading.value = true;

  let errors = [];
  await schema.validate(registerData.value, { abortEarly: false }).catch((err) => {
    err.inner.forEach((e) => {
      errors.push({
        name: e.path,
        msg: e.message,
      });
    });
  });

  if (errors.length > 0) {
    loading.value = false;
    setFormFieldsError(errors);
    return;
  }

  await AuthService.register(registerData.value).then(
    (response) => {
      loading.value = false;
      Notify.success("Registro realizado com sucesso!");

      registeredSuccessfully.value = true;
    },
    (error) => {
      loading.value = false;
      const data = error.response?.data;
      if (!data) return;
      const errorResponse = data.error;
      setFormFieldsError(
        errorResponse.fieldsErrors.map((error) => {
          return {
            name: error.fieldName,
            msg: error.validationMessage,
          };
        })
      );
    }
  );
}
function toggleForm(form) {
  emit("toggleForm", form);
}
</script>
<style scoped>
.radio input ~ label {
  background-color: rgb(233, 225, 225);
  color: rgb(158, 146, 146);
}

.radio input:checked ~ label {
  background-color: #059669;
  color: white;
}
</style>
