<template>
  <li v-if="menu.hasPermission && !menu?.subMenus">
    <router-link :to="menu.path">
      <a href="#" :class="`
        relative
        flex
        flex-row
        items-center
        h-10
        focus:outline-none
        text-gray-800
        hover:bg-gray-200
        border-l-4
        border-transparent
        hover:border-blue-700
        pr-6
        ${isRouteSelected(menu) ? 'border-blue-700 bg-gray-200' : ''}`">
        <span class="inline-flex items-center ml-4">
          <component class="h-5 w-5 mb-1" v-bind:is="menu.icon" />
        </span>
        <span class="ml-2 tracking-wide truncate">{{ menu.text }}</span>
        <!--  <span
                class="hidden md:block px-2 py-0.5 ml-auto text-xs font-medium tracking-wide text-blue-500 bg-indigo-50 rounded-full">New</span> -->
      </a>
    </router-link>
  </li>
  <li v-else-if="menu.hasPermission">
    <div>
      <div as="template" @click="toggleSubMenu">
        <a :class="`
          sidebar-ignored
          cursor-pointer
          relative 
          flex
          flex-row
          items-center
          h-10
          focus:outline-none
          text-gray-800
          hover:bg-gray-200
          border-l-4
          border-transparent
          hover:border-blue-700
          pr-6`">
          <span class="inline-flex items-center ml-4">
            <component class="h-5 w-5 mb-1" v-bind:is="menu.icon" />
          </span>
          <span class="ml-2 tracking-wide truncate">
            {{ menu.text }}
          </span>
          <component v-if="!isSubMenuVisible" class="ml-2 h-4 w-4 mb-1" is="ChevronDownIcon" />
          <component v-else class="ml-2 h-5 w-5 mb-1" is="ChevronUpIcon" />
        </a>
      </div>

      <Transition mode="out-in">
        <div v-if="isSubMenuVisible" key="sub-menu">
          <div v-for="subMenu in menu.subMenus" :key="subMenu.href">
            <router-link :to="subMenu.path">
              <a v-if="subMenu.hasPermission" href="#" :class="`relative flex flex-row items-center pl-2 h-11 focus:outline-none text-gray-800 hover:bg-gray-200 bg-white border-l-4 border-transparent pr-6 hover:border-blue-700
                  ${isRouteSelected(subMenu) ? 'border-blue-700 bg-gray-200' : ''} 
                `">
                <span class="inline-flex items-center ml-4">
                  <component class="h-5 w-5 mb-1" v-bind:is="subMenu.icon" />
                </span>
                <span class="ml-2 tracking-wide truncate">{{ subMenu.text }}</span>
              </a>
            </router-link>
          </div>
        </div>
      </Transition>
    </div>
  </li>
</template>
<script>
import GraphLineUpIcon from "../icons/GraphLineUpIcon.vue";
import CompanyIcon from "../icons/CompanyIcon.vue";
import UserIcon from "../icons/UserIcon.vue";
import UsersIcon from "../icons/UsersIcon.vue";
import SupportIcon from "../icons/SupportIcon.vue";
import ChickenIcon from "../icons/ChickenIcon.vue";
import LabelIcon from "../icons/LabelIcon.vue";
import ChevronDownIcon from "../icons/ChevronDownIcon.vue";
import ChevronUpIcon from "../icons/ChevronUpIcon.vue";
import RequestIcon from "../icons/RequestIcon.vue";
import CookHatIcon from "../icons/CookHatIcon.vue";
import BrandIcon from "../icons/BrandIcon.vue";
import TrackingIcon from "../icons/TrackingIcon.vue";
import IntegrationIcon from "../icons/IntegrationIcon.vue";
import CreditCardIcon from "../icons/CreditCardIcon.vue";
import TagIcon from "../icons/TagIcon.vue";
export default {
  components: {
    GraphLineUpIcon,
    CompanyIcon,
    UserIcon,
    UsersIcon,
    ChickenIcon,
    CookHatIcon,
    LabelIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    SupportIcon,
    RequestIcon,
    BrandIcon,
    IntegrationIcon,
    CreditCardIcon,
    TrackingIcon,
    TagIcon,
  },
  props: {
    menu: Object,
  },
  data() {
    return {
      isSubMenuVisible: false,
    };
  },
  methods: {
    toggleSubMenu() {
      this.isSubMenuVisible = !this.isSubMenuVisible;
    },
    isRouteSelected(menu) {
      if (!this.$route?.matched[0]?.path) return false;
      return (
        this.$route.matched.some((route) => route.path.includes(menu.path)) ||
        this.$route.matched[0]?.meta.id == menu.id
      );
    },
  },
};
</script>

<style>
/* .v-enter-from,
.v-leave-to {
  transform: translateY(-50px);
  opacity: 0;
}

.v-enter-active,
.v-leave-active {
  transition: all 0.3s;
} */
</style>
