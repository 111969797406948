
import Login from '../views/login/Index.vue';
const PasswordRecoveryComponent = () => import('../views/login/PasswordRecovery.vue')
const EmailConfirmationComponent = () => import('../views/login/EmailConfirmation.vue')
export default [
    {
        path: '/',
        redirect: '/login'
    },
    {
        path: '/login',
        component: Login,
        meta: { id: "login", name: 'Login' }
    },
    {
        path: '/password-recovery/:token',
        component: PasswordRecoveryComponent,
        meta: { id: "password-recovery", name: 'Recuperar senha' }
    },
    {
        path: '/confirm-email/:token',
        component: EmailConfirmationComponent,
        meta: { id: "confirm-email", name: 'Confirmar e-mail' }
    },
]