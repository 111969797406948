import { defineStore } from "pinia";
import router from '../../../router'
import AuthService from '../services/auth.service';
import { Role } from "../../../_helpers/role";

const userLocalStorage = JSON.parse(localStorage.getItem('user'));

export const useAuth = defineStore("auth", {
  state: () => {
    return {
      user: userLocalStorage
        ? { status: { loggedIn: true }, data: userLocalStorage }
        : { status: { loggedIn: false }, data: null }
    }
  },
  actions: {
    async login(user) {
      return await AuthService.login(user)
        .then(
          user => {
            this.setUserState(user, true)
            return Promise.resolve(user);
          },
          error => {
            this.setUserState(null, false)
            return Promise.reject(error);
          }
        );
    },
    async refreshToken() {
      return await AuthService.refreshToken(this.user.data.refreshToken)
        .then(
          user => {
            this.setUserState(user, true)
            return Promise.resolve(user);
          },
          error => {
            this.setUserState(null, false)
            return Promise.reject(error);
          }
        );
    },
    async logout() {
      this.setUserState(null, false)
      await new Promise(r => setTimeout(r, 1000));
      await AuthService.logout().then(() => router.push("/login"));
    },
    setUserState(user, loggedIn) {
      this.user.status.loggedIn = loggedIn;
      this.user.data = user;
    },
  },
  getters: {
    getUserRole: (state) =>
      state.user?.data?.permissions?.role,
    getUserPermissions: (state) =>
      state.user?.data?.permissions.privileges,
    hasValidSignature: (state) =>
      state.user?.data?.signatureStatus == 'TIMELY_PAYMENT',
    hasClientUserAdminRole: (state) =>
      state.user?.data ? state.user?.data?.permissions.role.includes(Role.ClientUserAdmin) : false,
    hasClientUserOperatorRole: (state) =>
      state.user?.data ? state.user?.data?.permissions.role.includes(Role.ClientUserOperator) : false,
    hasClientNutritionistUserRole: (state) =>
      state.user?.data ? state.user?.data?.permissions.role.includes(Role.ClientNutritionistUser) : false,
  }
});