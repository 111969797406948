import http from '../../../http'

const PATH = 'platform-feedback';

class PlatformFeedbackService { 
  register(feedback) {
    return http.post(PATH + '/register', {
      ...feedback
    });
  }
}

export default new PlatformFeedbackService();