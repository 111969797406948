import { createRouter, createWebHistory } from "vue-router"
import { useAuth } from '../views/login/store'
import { useApp } from '../store/index'

import auth from "./auth"
import businessRoutes from "./business"
import { hasPermission } from "../_helpers/permission"

//not found
const NotFoundComponent = () => import('../views/404.vue')
const notFoundRoute = [
    { path: "/:catchAll(.*)", component: NotFoundComponent }
]

const routes = [
    ...auth,
    ...businessRoutes,
    ...notFoundRoute
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

const publicPages = ['/login', '/confirm-email', 'password-recovery'];
router.beforeEach((to, from, next) => {
    const authStore = useAuth();
    const appStore = useApp()
    const { permission, name } = to.meta;
    document.title = name ? "RótuloAgil | ".concat(name) : "RótuloAgil";

    const authRequired = !publicPages.find(route => {
        if (to.path.includes(route)) {
            return true;
        }
    });

    const loggedIn = authStore.user.status.loggedIn;

    let userRole = authStore.getUserRole;
    if (!userRole && authRequired) {
        authStore.logout();
    }
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
        next('/login');
    } else if (userRole && permission && !hasPermission(permission)) {
        next('/');
    }
    else {
        appStore.loading = true; // create transaction effetcs
        next()
    }
});

export default router;