<template>
  <!-- Header -->
  <div class="fixed w-full flex items-center justify-between h-14 z-10 shadow-md bg-white">
    <span @click="toggleSidebar()" class="pl-2 block md:hidden sidebar-ignored">
      <HamburguerIcon class="h-8 w-8" />
    </span>
    <div class="flex items-center justify-center w-64 h-14 border-none pl-4 sm:pl-0">
      <img class="w-23 h-14 mr-2 p-0.5 rounded-md overflow-hidden" :src="logoPath" />
    </div>
    <div class="flex justify-end items-center h-14 header-right">
      <ul class="flex items-center">
        <li>
          <div class="block w-px h-6 mx-3 bg-gray-400"></div>
        </li>
        <li>
          <a @click="logout()" class="flex items-center mr-4 cursor-pointer">
            <LogOutIcon class="h-5 w-5 mr-2" color="#f3f4f6" />
            Sair
          </a>
        </li>
      </ul>
    </div>
  </div>
  <!-- ./Header -->
</template>
<script setup>
import { useAuth } from "../../views/login/store";
import LogOutIcon from "../icons/LogOutIcon";
import HamburguerIcon from "../icons/HamburguerMenuIcon.vue";
import { onMounted } from "vue";
const logoPath = require("@/assets/logo.png");
const store = useAuth();
const { logout } = store;

onMounted(() => {
  handleSidebarBehavior();
});

function handleSidebarBehavior() {
  const sidebar = document.querySelector(".sidebar");
  const ignoredElements = document.getElementsByClassName('sidebar-ignored');
  document.addEventListener('click', function (event) {
    const hasIgnoredElement = Array.from(ignoredElements)
      .some(element => element.contains(event.target));

    if (!hasIgnoredElement && sidebar.classList.contains("expanded")) {
      //to close sidebar when click out.
      toggleSidebar();
    }
  });
}

function toggleSidebar() {
  const sidebar = document.querySelector(".sidebar");
  sidebar.classList.toggle("expanded");
}
</script>
<style>
.sidebar {
  width: 0rem;
  /* Width when not expanded */
  transition: width 0.5s;
}

.sidebar.expanded {
  width: 16rem;
  /* Width when expanded */
  visibility: visible;
}
</style>
