<template>
  <svg
    fill="#000000"
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 70 70"
    enable-background="new 0 0 70 70"
    xml:space="preserve"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <g>
        <path
          d="M62.131,8.45c-3.445-3.446-8.139-5.344-13.215-5.344c-5.678,0-11.348,2.413-15.554,6.619 C26.787,16.3,19.139,31.689,20.874,41.44l-7.891,7.891c-2.729-1.6-6.083-1.244-8.414,1.086c-2.717,2.717-2.726,7.131-0.02,9.84 c0.886,0.885,1.969,1.506,3.15,1.814c0.316,1.184,0.941,1.927,1.815,2.8c1.315,1.314,3.067,1.712,4.933,1.712 c0.016,0,0.031,0,0.047,0c1.861,0,3.604-0.372,4.91-1.677c2.08-2.08,2.486-5.259,1.21-7.813l7.712-7.619 c1.149,0.281,2.419,0.469,3.802,0.469c9.404,0,22.688-6.707,28.727-12.747c3.987-3.986,6.328-9.143,6.594-14.54 C67.719,17.186,65.829,12.148,62.131,8.45z M16.605,55.63c-0.781,0.779-0.781,2.047-0.001,2.828 c0.911,0.91,1.098,2.842-0.027,3.965c-0.555,0.557-1.312,0.869-2.103,0.854c-0.807-0.006-1.563-0.32-2.131-0.889 c-0.558-0.557-0.878-1.324-0.88-2.105c-0.003-1.102-0.896-1.992-1.997-1.994c-0.79-0.002-1.532-0.309-2.089-0.865 c-1.146-1.146-1.138-3.021,0.02-4.178c1.236-1.238,3.025-1.176,4.35,0.148c0.375,0.375,0.884,0.586,1.414,0.586 s1.039-0.211,1.414-0.586l7.848-7.846c0.337,0.52,0.716,1.01,1.158,1.451c0.276,0.277,0.575,0.531,0.887,0.77L16.605,55.63z M63.454,22.471c-0.217,4.403-2.144,8.636-5.427,11.919c-5.475,5.474-17.714,11.597-25.898,11.597c-2.59,0-4.515-0.611-5.72-1.816 c-5.414-5.416,2.362-24.198,9.781-31.618c3.462-3.462,8.101-5.447,12.726-5.447c4.008,0,7.696,1.481,10.387,4.172 C62.192,14.167,63.667,18.143,63.454,22.471z"
        ></path>
        <path
          d="M54.475,11.944c-0.491-0.249-1.095-0.05-1.344,0.441c-0.249,0.493-0.051,1.095,0.441,1.344 c0.921,0.465,1.757,1.069,2.483,1.796c0.195,0.195,0.451,0.293,0.707,0.293s0.512-0.098,0.707-0.293 c0.391-0.391,0.391-1.023,0-1.414C56.593,13.234,55.585,12.504,54.475,11.944z"
        ></path>
        <path
          d="M47.407,10.729c-3.204,0.358-6.274,1.861-8.645,4.232c-2.686,2.685-5.54,7.548-7.104,12.104 c-0.179,0.522,0.1,1.091,0.622,1.271c0.107,0.036,0.217,0.054,0.324,0.054c0.415,0,0.804-0.261,0.946-0.676 c1.473-4.293,4.136-8.849,6.625-11.338c2.051-2.052,4.697-3.351,7.453-3.658c0.549-0.062,0.943-0.557,0.883-1.105 C48.451,11.064,47.961,10.667,47.407,10.729z"
        ></path>
        <path
          d="M9.724,52.583c-0.004,0-0.008,0-0.011,0c-0.567,0-1.668,0.747-2.201,1.974c-0.221,0.506,0.012,1.315,0.519,1.536 c0.13,0.057,0.265,0.174,0.397,0.174c0.387,0,0.754-0.189,0.918-0.566c0.132-0.301,0.334-0.344,0.421-0.354 c0.537-0.023,0.963-0.842,0.957-1.383C10.718,53.415,10.271,52.583,9.724,52.583z"
        ></path>
      </g>
    </g>
  </svg>
</template>
<script>
import icon from "./icon.js";
export default Object.assign({}, icon);
</script>
