<template>
    <Dialog ref="dialog">
        <template v-slot:dialog-buttons="slotProps">
            <button type="button"
                class="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-1 px-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                @click="closeDialog()" ref="cancelButtonRef">
                OK
            </button>
        </template>
    </Dialog>
</template>
<script setup>
import { onMounted, ref } from "vue";
import Dialog from "../components/Dialog.vue";

const dialog = ref(null);

onMounted(() => {
    window.addEventListener("offline", (event) => {
        showDialog()
    });
});

function showDialog() {
    dialog.value.openDialog({
        title: "Conexão instável",
        description: "Identificamos uma instabilidade na sua conexão com a internet!",
        icon: "NoInternetIcon",
    });
}

function closeDialog() {
    dialog.value.closeDialog();
}
</script>