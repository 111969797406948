import http from '../../../http'

const COMPANY_PATH = '/dashboard';

class DashboardService {
  getActivedRecipes() {
    return http.get(COMPANY_PATH.concat('/actived-recipes-count'));
  }

  getInactivedRecipes() {
    return http.get(COMPANY_PATH.concat('/inactivated-recipes-count'));
  }
}

export default new DashboardService();