<template>
  <div class="border border-gray-200 m-2 w-full bg-white">
    <div class="p-5" v-if="Object.keys(data).length > 0">
      <h5 class="text-md tracking-tight text-gray-800">
        {{ data.name }}
      </h5>

      <h5
        :class="`text-${data.color}-500`"
        class="font-bold text-4xl tracking-tight text-center m-2"
      >
        {{ data.value }}
      </h5>
      <div></div>
    </div>
    <div class="p-5 flex items-center justify-center" v-else>
      <span><SpinnerIcon class="animate-spin w-8" color="#f3f4f6" /></span>
    </div>
  </div>
</template>
<script>
import SpinnerIcon from "../icons/SpinnerIcon.vue";
export default {
  components: {
    SpinnerIcon,
  },
  props: {
    data: {},
  },
};
</script>
