<template>
  <Loading v-if="loading" />
  <Dialog :open="true" ref="feedbackDialog">
    <template v-slot:dialog-body="slotProps">
      <p class="text-sm text-gray-600">
        Agradecemos desde já, sua opinião é muito importante para o aperfeiçoamento da
        plataforma!
      </p>
      <p class="text-sm text-gray-600 mt-2 mb-2">
        O que você tem a nos dizer sobre a página atual ou sobre a plataforma como um
        todo?
      </p>
      <textarea
        rows="4"
        v-model="feedback.description"
        class="w-full block p-2.5 text-sm text-gray-900 rounded-lg border border-gray-300 focus:outline-none focus:shadow-outline"
        placeholder="Ex: Eu acho que poderia ter mais cores!"
      ></textarea>
    </template>
    <template v-slot:dialog-buttons="slotProps">
      <div>
        <a
          @click="sendFeedback(slotProps.dialogData.feedbackTypeParam)"
          type="button"
          class="cursor-pointer w-full inline-flex justify-center rounded-md border border-transparent shadow-sm py-1 px-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
        >
          <SendIcon class="h-5 w-5 mr-2" color="#f3f4f6" />
          Enviar
        </a>
        <a
          type="button"
          class="cursor-pointer w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-1 px-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
          @click="closeFeedbackDialog()"
          ref="cancelButtonRef"
        >
          <CancelIcon class="h-5 w-5 mr-2" color="#f3f4f6" />
          Cancelar
        </a>
      </div>
    </template>
  </Dialog>
  <div class="text-center text-gray-600 w-full p-2 grid grid-cols-1">
    <div class="col-start-1 col-span-1 flex items-center justify-center align-middle">
      <p>O que achou desta página?</p>
      <a class="cursor-pointer" @click="showFeedbackDialog(feedbackType.POSITIVE)"
        ><ThumbUpIcon class="h-6 w-6 ml-2" color="#059669"
      /></a>
      <a class="cursor-pointer" @click="showFeedbackDialog(feedbackType.NEGATIVE)"
        ><ThumbDownIcon class="h-6 w-6 ml-2" color="#ef4444"
      /></a>
    </div>
    <p class="text-xs font-bold">
      {{ `© ${new Date().getFullYear()} RotuloÁgil ${version}.` }}
    </p>
  </div>
</template>
<script setup>
import { ref } from "vue";
import { useRoute } from "vue-router";
import Dialog from "../Dialog.vue";
import SendIcon from "../icons/SendIcon.vue";
import CancelIcon from "../icons/CancelIcon.vue";
import ThumbUpIcon from "../icons/ThumbUpIcon.vue";
import ThumbDownIcon from "../icons/ThumbDownIcon.vue";
import PlatformFeedbackService from "./services/platform-feedback.service";
import Notify from "./../../_helpers/toastNotification";
import Loading from "../Loading.vue";
const { version } = require("../../../package.json");
const feedbackDialog = ref(null);
const feedback = ref({});
const router = useRoute();
const loading = ref(false);

const feedbackType = {
  POSITIVE: "POSITIVE",
  NEGATIVE: "NEGATIVE",
};

function showFeedbackDialog(feedbackTypeParam) {
  feedbackDialog.value.openDialog({
    feedbackTypeParam: feedbackTypeParam,
    title: "Queremos a sua opinião!!",
    icon: "QuestionCicleIcon",
  });
}

function closeFeedbackDialog() {
  feedback.value = {};
  feedbackDialog.value.closeDialog();
}

function sendFeedback(feedbackType) {
  const request = {
    description: feedback.value.description,
    pagePath: router.path,
    feedbackType: feedbackType,
  };

  loading.value = true;
  PlatformFeedbackService.register(request).then(() => {
    loading.value = false;
    Notify.success("Feedback enviado com sucesso!");
    closeFeedbackDialog();
  });
}
</script>
