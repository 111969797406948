<template>
  <div>
    <div>
      <span class="text-sm text-gray-900">Bem vindo de volta</span>
      <h1 class="text-2xl font-bold">Entre na sua conta</h1>
    </div>
    <div class="mt-2">
      <span
        name="messageError"
        class="bg-red-100 text-red-600 text-sm validation-span min-w-100 rounded"
        :class="userNotFoundError ? ['block p-2'] : ['hidden']"
      />
    </div>
    <div class="mt-4">
      <label class="block text-md mb-2" for="email">Email ou username</label>
      <input
        class="px-4 w-full border-2 py-2 rounded-md text-md outline-none"
        id="email"
        autocomplete="on"
        :class="userNotFoundError ? ['border border-red-500'] : ['']"
        type="email"
        name="email"
        v-model="loginData.usernameOrEmail"
        placeholder="Email ou username"
        @keyup.enter="handleLogin()"
      />
    </div>
    <div class="my-5">
      <label class="block text-md mb-2" for="password">Senha</label>
      <div class="relative">
        <input
          v-model="loginData.password"
          class="px-4 w-full border-2 py-2 rounded-md text-md outline-none"
          id="password"
          :class="userNotFoundError ? ['border border-red-500'] : ['']"
          :type="`${showPassword ? 'text' : 'password'}`"
          name="password"
          placeholder="Password"
          @keyup.enter="handleLogin()"
        />
        <a
          :class="
            userNotFoundError ? ['border-t border-r border-b border-red-500'] : ['']
          "
          class="absolute top-0 right-0 h-full px-2 py-2 bg-gray-200 rounded-r shadow-sm cursor-pointer"
          @click="showPassword = !showPassword"
        >
          <EyesIcon class="h-6 w-6" color="#000000" v-if="!showPassword" />
          <HiddenEyesIcon class="h-6 w-6" color="#000000" v-else />
        </a>
      </div>
      <!-- <span name="password" class="text-red-600 text-sm validation-span" /> -->
    </div>
    <div class="flex justify-between mb-3">
      <div></div>
      <a
        class="text-sm text-blue-700 hover:underline cursor-pointer"
        @click="toggleForm('')"
        >Esqueceu a senha?</a
      >
    </div>

    <div>
      <button
        @click="handleLogin()"
        class="w-full uppercase inline-flex justify-center items-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
      >
        <SpinnerIcon v-show="loading" class="animate-spin h-5 w-5 mr-2" color="#f3f4f6" />
        Entrar
      </button>
    </div>

    <!--  <div
        class="flex items-center space-x-2 justify-center bg-gray-700 hover:bg-gray-600 text-white py-2 rounded-md transition duration-100"
      >
        <img
          class="h-4 cursor-pointer"
          src="https://i.imgur.com/arC60SB.png"
          alt=""
        />
        <button>Entre com o Google</button>
      </div> -->

    <p class="mt-8">
      Não tem uma conta?
      <a class="cursor-pointer text-sm text-blue-600" @click="toggleForm('register')"
        >Cadastre-se grátis</a
      >
    </p>
  </div>
</template>
<script setup>
import { useRouter } from "vue-router";
import { useAuth } from "./store";
import * as yup from "../../validator";
import { onMounted, ref } from "vue";
import EyesIcon from "../../components/icons/EyesIcon.vue";
import SpinnerIcon from "../../components/icons/SpinnerIcon.vue";
import { setFormFieldsError } from "../../_helpers/formFieldsHandler";
import HiddenEyesIcon from "../../components/icons/HiddenEyesIcon.vue";
import Notify from "./../../_helpers/toastNotification";

const emit = defineEmits(["toggleForm"]);

const schema = yup.object().shape({
  usernameOrEmail: yup.string().required().max(50).label("Email/username").min(5),
  password: yup.string().required().label("Senha").max(50).min(2),
});

const loginData = ref({});
const loading = ref(false);
const showPassword = ref(false);
const router = useRouter();
const store = useAuth();
let userNotFoundError = false;

const { login } = store;

onMounted(() => {
  if (store.user.status.loggedIn) {
    router.push("/dashboard");
  }
});

function toggleForm(form) {
  emit("toggleForm", form);
}

async function handleLogin() {
  loading.value = true;

  let errors = [];
  await schema.validate(loginData.value, { abortEarly: false }).catch((err) => {
    err.inner.forEach((e) => {
      errors.push({
        name: e.path,
        msg: e.message,
      });
    });
  });

  if (errors.length > 0) {
    loading.value = false;
    setFormFieldsError(errors);
    return;
  }

  await login(loginData.value).then(
    () => {
      router.push("/dashboard");
      loading.value = false;
      Notify.success("Login realizado com sucesso!");
    },
    (error) => {
      console.error(error);
      if (error.response.status == 401) {
        userNotFoundError = true;
        setFormFieldsError([
          {
            name: "messageError",
            msg: "E-mail ou senha inválido(s)!",
          },
        ]);
      }

      loading.value = false;
    }
  );
}
</script>

<style>
.min-w-100 {
  width: 100% !important;
}
</style>
