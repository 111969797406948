<template>
  <div class="bg-white overflow-hidden m-2">
    <div class="px-4 py-5 sm:px-6">
      <h3 class="text-2xl leading-6 font-medium text-gray-900">Dashboard</h3>
      <p class="mt-1 max-w-2xl text-sm text-gray-500">
        Dashboard com informações centralizadas.
      </p>
    </div>
    <div class="border-t border-gray-200 bg-gray-200">
      <div class="grid grid-cols-3 gap-3 xl:grid-cols-5 xl:gap-5 lg:grid-cols-5 lg:gap-5">
        <ClientUserAdminDashboard v-if="hasClientUserAdminRole" />
        <ClientUserOperatorDashboard v-if="hasClientUserOperatorRole" />
        <ClientNutritionistUserDashboard v-if="hasClientNutritionistUserRole" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { useAuth } from "../login/store";
import ClientUserAdminDashboard from "./client/AdminIndex.vue";
import ClientUserOperatorDashboard from "./client/OperatorIndex.vue";
import ClientNutritionistUserDashboard from "./nutritionist/Index.vue";
import { useApp } from "../../store";
import { onMounted } from "vue";

const store = useAuth();
const appStore = useApp();

const {
  hasClientUserAdminRole,
  hasClientNutritionistUserRole,
  hasClientUserOperatorRole,
} = store;

onMounted(() => (appStore.loading = false));
</script>
