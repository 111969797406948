<template>
  <svg
    width="256px"
    height="256px"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <g id="Edit / List_Check">
        <path
          id="Vector"
          d="M4 17H11M20 14L16 18L14 16M4 12H15M4 7H15"
          :stroke="color"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        ></path>
      </g>
    </g>
  </svg>
</template>
<script>
import icon from "./icon.js";
export default Object.assign({}, icon);
</script>
