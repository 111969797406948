<template>
  <svg fill="#000000" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
    <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
    <g
      id="SVGRepo_tracerCarrier"
      stroke-linecap="round"
      stroke-linejoin="round"
    ></g>
    <g id="SVGRepo_iconCarrier">
      <path d="M27,3H5A1,1,0,0,0,5,5H27a1,1,0,0,0,0-2Z"></path>
      <path d="M27,7H5A1,1,0,0,0,5,9H27a1,1,0,0,0,0-2Z"></path>
      <path d="M27,11H5a1,1,0,0,0,0,2H27a1,1,0,0,0,0-2Z"></path>
      <path d="M27,15H5a1,1,0,0,0,0,2H27a1,1,0,0,0,0-2Z"></path>
      <path d="M27,19H5a1,1,0,0,0,0,2H27a1,1,0,0,0,0-2Z"></path>
      <path d="M27,23H5a1,1,0,0,0,0,2H27a1,1,0,0,0,0-2Z"></path>
      <path d="M27,27H5a1,1,0,0,0,0,2H27a1,1,0,0,0,0-2Z"></path>
    </g>
  </svg>
</template>
