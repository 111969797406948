<template>
    <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
        <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
        <g id="SVGRepo_iconCarrier">
            <path fill-rule="evenodd" clip-rule="evenodd"
                d="M20 4C21.6569 4 23 5.34315 23 7V17C23 18.6569 21.6569 20 20 20H4C2.34315 20 1 18.6569 1 17V7C1 5.34315 2.34315 4 4 4H20ZM20 6C20.5523 6 21 6.44772 21 7V9H3V7C3 6.44771 3.44772 6 4 6H20ZM3 11V17C3 17.5523 3.44772 18 4 18H20C20.5523 18 21 17.5523 21 17V11H3Z"
                :fill="color"></path>
        </g>
    </svg>
</template>
<script>
import icon from "./icon.js";
export default Object.assign({}, icon);
</script>